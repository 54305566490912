import { render, staticRenderFns } from "./pubblicazioni.html?vue&type=template&id=a5567d72&scoped=true&"
import script from "./pubblicazioni.js?vue&type=script&lang=js&"
export * from "./pubblicazioni.js?vue&type=script&lang=js&"
import style0 from "./pubblicazioni.scss?vue&type=style&index=0&id=a5567d72&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a5567d72",
  null
  
)

export default component.exports