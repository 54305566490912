export default {
  name: "pubblicazioni",
  components: {},
  props: [],
  data() {
    return {
      hei: null,
      itemsPubbl: [],
    };
  },
  computed: {},
  mounted() {
    this.getWindowHeight();
    window.addEventListener("resize", this.getWindowHeight);
    console.log("pubblicazioni -> mounted starts");
    if (this.$store.getters.isPubblLoaded) {
      console.log("Pubblicazioni pronte nello store, no AXIOS");
      this.itemsPubbl = this.$store.getters.getPubbl;
    } else {
      this.$store.dispatch("loadPubbl");
    }
    this.$store.subscribe((mutation) => {
      console.log("STORE: rilevata mutation [" + mutation.type + "]");
      if (mutation.type === "setPubbl") {
        this.itemsPubbl = this.$store.getters.getPubbl;
      }
    });
  },
  methods: {
    getWindowHeight() {
      this.hei = String(document.documentElement.clientHeight - 350) + "px";
    },
    pubblImg(i) {
      return '<img src="https://poesialirica.it/jpg/' + i + '">';
    },
  },
};
